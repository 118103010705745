import React from 'react'
import { graphql, navigate } from 'gatsby'

export default class Story extends React.Component {

  state = {
    data: [],
    language: null
  }

  componentDidMount() {
    const {
      pageContext: {
        SHOPIFY_PRODUCT
      },
      data: {
        allPrismicStory: {
          nodes
        }
      }
    } = this.props;
    
    const language = this.setupLanguage();
    
    const story = nodes.filter(item => item.lang === language.prismic).map((item) => {
      const product = item.data.shopify_item;
      const content = item.data.printable_full_content;
      const demo = item.data.printable_demo_content;
      return {
        uid: item.uid,
        in_app_purchase: JSON.parse(item.data.in_app_purchase),
        physical_book: JSON.parse(item.data.physical_book),
        printable: JSON.parse(item.data.printable),
        printable_demo_content: item.data.printable_demo_content,
        product: product && `${SHOPIFY_PRODUCT}/${product.handle}`,
        type: item.data.type,
        content: content && content.url,
        demo: demo && demo.url,
      }
    })[0];
    
    const state = { 
      data: story,
      language
    };
    
    this.setState(state);
    this.handleRedirect(state);
  }

  setupLanguage = () => {
    const {
      location,
      pageContext: { 
        countries, 
        content: {
          data
        }
      }
    } = this.props;

    const search = location.search.split('=');
    const param = search.length === 2 && search[0].replace('?', '');
    const value = search.length === 2 && search[1];

    let lang;
    let prismicLang;

    if (!param || !value) {
      lang = 'en';
      if (data) {
        let uri = location.pathname.split('/').slice(-2).join('/');
        if (uri === 'shop/book') {
          lang = 'fr';
        }
      }
    } else {
      if (param === 'language' && value) {
        lang = (value === 'en' || value === 'fr') ? value : (
          Object.keys(countries).map(key => {
            return {
              lang: key,
              active: countries[key].filter(item => item === value).length > 0
            }
          }).filter(item => item.active)[0].lang
        );
      }
    }
    prismicLang = lang === 'fr' ? 'fr-fr' : 'en-us';

    return {
      query: lang,
      prismic: prismicLang
    }
  }

  handleRedirect = (state) => {
    const { location, path } = this.props;
    const { data, language } = state;

    let redirectUrl = null;
    
    const extractPath = path.split('/').filter(i => i !== '');
    const urlType = extractPath[extractPath.length - 1].split('?')[0];
    const wrongUrl = extractPath[extractPath.length - 1] === 'shop' && (
      `/${extractPath.slice(0, (extractPath.length - 1)).join('/')}${location.search}`
    );
    
    if (typeof data !== 'undefined') {
      if (urlType === 'printables') {
        if (data.printable && data.content) {
          redirectUrl = {
            type: 'dist',
            url: data.content,
          };
        } else {
          redirectUrl = {
            type: 'dist',
            url: `/${language.query}/404/`,
          };
        }
      } else if (urlType === 'demo') {
        if (data.printable && data.demo) {
          redirectUrl = {
            type: 'dist',
            url: data.demo,
          };
        } else {
          redirectUrl = {
            type: 'dist',
            url: `/${language.query}/404/`,
          };
        }
      } else if (urlType === 'shop' || urlType === 'book') {
        if (data.physical_book && !wrongUrl) {
          redirectUrl = {
            type: 'dist',
            url: data.product,
          };
        } else {
          redirectUrl = {
            type: 'dist',
            url: !wrongUrl ? `/${extractPath.join('/')}` : wrongUrl
          };
        }
      }
    } else {
      redirectUrl = {
        type: 'dist',
        url: `/${language.query}/404/`,
      };
    }

    if (redirectUrl) {
      if (redirectUrl.type === 'dist') {
        window.location.href = redirectUrl.url;
      } else {
        navigate(redirectUrl.url)
      }
    }
  }

  render() {
    return <div></div>;
  }
}

export const pageQuery = graphql`
  query StoryByProjectId($id: Date) {
    allPrismicStory(filter: {data: {project_id: {eq: $id}}}) {
      nodes {
        id
        lang
        uid
        data {
          project_id
          type
          physical_book
          printable
          in_app_purchase
          printable_full_content {
            url
          }
          printable_demo_content {
            url
          }
          shopify_item {
            handle
          }
        }
      }
    }
  }
`;